import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgFluidWebp from "../components/img-fluid-webp"
import ImgFluid from "../components/img-fluid"

import RWPlan from "../images/brandschutz-RWPlan-opt.inline.svg"

import "../scss/site.scss"
import "../scss/service.scss"

const LeistungBrandschutz = () => {
  return (
    <Layout>
      <SEO
        title="Vorbeugender Brandschutz | freie ingenieure bau"
        description="Unsere Leistungen: Brandschutzkonzepte, Feuerwiderstandsdauer für Tragwerke, Flucht- und Rettungspläne, Numerische Simulation von Brand- und Rauchentwicklung u.v.m."
        lang="de"
      />

      <SEO title="Brandschutzkonzepte und Brandschutzplanung" />
      <div className="fib-sp80"></div>
      <div className="fib-sp40 uk-visible@m"></div>
      <div className="fib-container-text">
        <div className="uk-grid-medium uk-flex-top" uk-grid="">
          <div className="uk-width-8-12@s uk-width-7-12@m">
            <h2 className="fib-ft54 fib-ft-bold">
              Wir beschäftigen uns mit großen Problemen, solange sie noch klein
              sind.
            </h2>
            <h1 className="fib-ft54 fib-ft-bold fib-ftgrey-light">
              Brandszenarien durchdenken und Bränden vorbeugen.
            </h1>
          </div>
          <div className="fib-sp40 uk-hidden@m"></div>
          <div className="uk-width-5-12@m">
            <div className="uk-flex uk-flex-center">
              <div className="uk-width-8-12@s uk-width-10-12@m">
                <ImgFluid
                  className="fib-services-img-isobox"
                  filename="isobox-fire-protection1.png"
                  alt="freie ingenieure bau - Isobox Brandschutz"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fib-sp120 uk-visible@m"></div>
        <div className="fib-sp120"></div>
        <div className="fib-services-divider-red"></div>
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>

        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-7-12@s">
            <h3 className="fib-services-h3">Leistungen Brandschutz</h3>
            <h4 className="fib-services-h4">
              Brandschutzkonzept für Neu- oder Umbauten
            </h4>
            <p>
              Ein hoch&shy;wertiges und um&shy;fassen&shy;des
              Brand&shy;schutz&shy;konzept ist ein wesent&shy;licher
              Grund&shy;stein für den rei&shy;bungs&shy;losen
              Projekt&shy;verlauf. Durch unsere jahre&shy;lange Erfah&shy;rung
              auch mit kom&shy;plexen Bau&shy;vor&shy;haben klären wir viele
              typische Problem&shy;stellungen bereits im Vor&shy;feld ab und
              redu&shy;zieren so die Risi&shy;ken für die Ausführungsphase.
            </p>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Brandschutztechnische Bewertung für Ihr Bestandsgebäude
            </h4>
            <p>
              Bei der Bewertung nach aktuellen Regeln der Technik ent&shy;stehen
              bei Bestands&shy;gebäuden schnell substan&shy;tielle
              Mängel&shy;protokolle mit hohen Folge&shy;kosten. Durch optimale
              Aus&shy;nutzung des aktiven und passiven Bestands&shy;schutzes
              reduzieren wir evtl. erfor&shy;derliche bauliche Maß&shy;nahmen
              auf das Minimum.
            </p>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Feuerwiderstandsdauer für ein Bestandstragwerk
            </h4>
            <p>
              Eine kurze Bestands&shy;aufnahme vor Ort und an&shy;schließende
              Ein&shy;stufung in norma&shy;tive Tabel&shy;len führt oft zum
              gewün&shy;schten Ergebnis. Falls nicht erstel&shy;len wir im
              Bedarfs&shy;fall auch einen computer&shy;gestützten Nachweis unter
              Ansatz eines realen Bemessungs&shy;brandes.
            </p>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Weitere Leistungen
            </h4>
            <ul className="fib-list">
              <li>Brandverhütungsschauen, Brandlastermittelung</li>
              <li>Numerische Simulation von Brand- und Rauchentwicklung</li>
              <li>
                Evakuierungskonzepte, Personenstromanalysen sowie
                Entrauchungskonzepte
              </li>
              <li>
                Flucht- und Rettungspläne, Feuerwehrpläne, Löschmittelnachweise,
                Brandschutzordnung
              </li>
            </ul>
            <div style={{ paddingTop: `60px` }}></div>
          </div>

          <div className="uk-width-5-12@s">
            <div className="uk-flex uk-flex-center">
              <div className="uk-width-8-12@m">
                <ImgFluidWebp
                  filename="img-partner1-portrait.jpg"
                  alt="freie ingenieure bau - Dr.-Ing. H. Belaschk"
                />
                <div style={{ paddingTop: `20px` }}></div>
                <p className="fib-ft18 fib-ft-bold">Dr.-Ing. H. Belaschk</p>
                <p className="fib-ft18" style={{ fontStyle: `italic` }}>
                  Zertifizierter Sachv&shy;erständi&shy;ger für den
                  vor&shy;beu&shy;gen&shy;den und gebäude&shy;tech&shy;nischen
                  Brand&shy;schutz (DIN EN ISO/IEC 17024)
                </p>
                <p className="fib-ft18 fib-ftgrey-dark">
                  <Obfuscate
                    className="fib-text-link fib-ft-red"
                    email="belaschk@freie-ingenieure-bau.de"
                  >
                    belaschk@freie-ingenieure-bau.de
                  </Obfuscate>
                  <br />
                  <span itemProp="telephone">
                    <a
                      className="fib-text-link fib-ft-red"
                      href="tel:+493098577374"
                    >
                      T +49 30 98577374
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="fib-sp80"></div>
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-9-12@m">
            <RWPlan alt="freie ingenieure bau - Beispiel Flucht- und Rettungsplan" />
          </div>
          <div className="uk-width-3-12@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `0px` }}
            >
              Flucht- und Rettungspläne für große und kleine Projekte
            </p>
          </div>
        </div>

        <div className="fib-sp40 uk-visible@m"></div>
        <div className="uk-flex uk-flex-column uk-flex-middle uk-flex-center@m uk-text-center@m">
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="brandschutz-30min.png"
              alt="freie ingenieure bau - Brand- und Rauchsimulationen"
            />
          </div>
          <div className="uk-width-6-12@m">
            <p className="fib-ft18" style={{ fontStyle: `italic` }}>
              Temperaturverteilung im Bauteil bei Brandeinwirkung
            </p>
          </div>
        </div>

        <div className="fib-sp40 uk-visible@m"></div>
        <div className="fib-sp40"></div>
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-9-12@m">
            <ImgFluidWebp
              filename="brandschutz-ueberlauf.png"
              alt="freie ingenieure bau - Brand- und Rauchsimulation"
            />
          </div>
          <div className="uk-width-3-12@m uk-flex-first@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `0px` }}
            >
              Brand- und Rauchsimulation
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LeistungBrandschutz
